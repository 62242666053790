<template>
  <div class="container text-center">

      <b-modal id="whatisit" ref="whatisit" size="xl" scrollable title="centre IA Education">
        <div v-html="whatisit"/>
      </b-modal>


    <div v-if="!user">

        <div v-if="!isConnecting && !isRegistering">
            <div v-if="true" class="row justify-content-center">
               <div class='col-4'><img class="logo" :src="isRegistering||isConnecting ? 'logo_coursGPT.png' : 'logo_coursGPT.png'" alt="coursGPT"></div>
            </div>
            <div v-if="!isConnecting && !isRegistering" class="row justify-content-center">
               <div class='col-4'><button @click="isConnecting=true" type="button" class="btn btn-primary buttons">{{$t('connexion')}}</button></div>
            </div>
            <div class="row justify-content-center">
               <div class='col-4'><button @click="isRegistering=true" type="button" class="btn btn-primary buttons">{{$t('inscription')}}</button></div>
            </div>

            <div class="row justify-content-center">
               <div class='col-4'><button type="button" @click="whatIsIt" class="btn btn-outline-primary buttons">{{$t('whatisit')}}</button></div>
            </div>
            <div class="row justify-content-center">
               <div class="col-4"><a class="col-9 col-md-4 col-lg-4" style="font-size: 0.8rem; text-decoration: none; color: grey;" href="https://centreIA.fr" target="_blank" rel="noopener noreferrer">centreIA.fr</a></div>
            </div>
        </div>

        <UserRegister v-if="isRegistering" @doReturn="doReturn" @onAlert="onAlert"/>
        <UserLogin v-if="isConnecting" @doReturn="doReturn" @onAlert="onAlert"/>
    </div>

    <div v-else>
      <UserView @onAlert="onAlert"/>
    </div>

  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import UserRegister from '@/components/UserRegister.vue'
import UserLogin from '@/components/UserLogin.vue'
import UserView from '@/views/UserView.vue'
//import NavBar from '@/components/NavBar.vue'


export default {
  data(){
      return {
        user: null,
        isConnecting: false,
        isRegistering: false,
        isAdministration: false,
        whatisit: "TBD",
      }
  },
  name: "MainView",
  components: {
    UserRegister,
    UserLogin, UserView
    //NavBar,
  },
  methods: {
    onAlert(variant, message) {this.$emit('onAlert', variant, message)},
    doReturn(){
      console.log('doReturn...')
      this.isConnecting=false;
      this.isRegistering=false;
      MyFlashDS.identifyUser().then(res=>{
        //alert(res.data);
        this.user=res.data;
        console.log(res.status, '<MyFlashDS.identifyUser', res.data);
        });
    },
    doAdmin(){
    },
    myChange() {
    },
    whatIsIt(){
      MyFlashDS.configGet('whatisit').then(res => {
        this.whatisit=res.data.whatisit;
        this.$refs.whatisit.show();
      });
    },
  },
  mounted() {
    //console.log('MOUNT MainMenu');
    if (this.$route.path=='/logout'){
        MyFlashDS.logoutUser()
        this.user=null;
    } else {
        MyFlashDS.identifyUser().then(res=>{
            this.user=res.data;
            //console.log(res.status, '<MyFlashDS.identifyUser', res.data);
            });
    }
  }
}
</script>


<style scoped>

button {
    cursor: pointer;
    width: 30vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    font-family: ITCAvantGarde, serif;
}

img.logo {
    //object-fit: cover;
    height: 40vh;
}

input {
    border: 1px solid var(--couleur-principale);
    width: 45vh;
}












  .buttonsX {
    color: black;
    margin-top: 2vh;
    background-color: rgb(0, 133, 197);
    border-radius: 7px;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    cursor: pointer;
    width: 30vh;
    }

/*
  container: {
    height: "90vh";
    display: "flex";
    alignItems: "center";
    textAlign: "center";
    marginBottom: '10vh';
  }
  button: {
    color: "white";
    marginTop: "2vh";
    backgroundColor: "#0085c5";
    borderRadius: "7px";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    fontSize: "1rem";
    cursor: "pointer";
    width: "30vh";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  kezako: {
    color: "#0085c5";
    backgroundColor: "#FFF";
    marginTop: "2vh";
    border: "2px solid #0085c5";
    borderRadius: "7px";
    fontSize: "1rem";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    cursor: "pointer";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  lien: {
    fontSize: "0.8rem";
    textDecoration: "none";
    color: "grey"
  };
  responsive: {
    height: "40vh"
  }
*/

</style>
