/*

Vue : {{ $route.params.type }}
*/

<template>
  <div class="container justify-content-center">
    <div>

    <NavBar :user="user" submenu="true"/>

    <h2 v-if="user">Bienvenue, {{user.pseudo}}</h2>
    <div v-if="user && user.roles && user.roles.length>0">
        <span v-if="user.roles[0].organisation">{{roles[user.roles[0].role]}} @ <b>{{user.roles[0].organisation.name}}</b></span>
        <span v-if="user.roles[0].group">, classe "{{user.roles[0].group.name}}"</span>
    </div>
    <div class="info">Dans le menu (en haut à gauche), vous pouvez accéder à votre synthèse, à l'historique ou aux questions</div>

    <!-- ******************************************************************************** -->

    <div v-if="$route.params.type == 'question'">
        <h3>Choix des questions</h3>
        Pour quelle technologie voulez-vous faire le test :
        <select @change="technoChange" name='techno' v-model="techno">
            <option value="" disabled >technologie :</option>
            <option v-for="(opt, idx) in technos" :value="opt.id || opt._id" :key="idx">{{opt.name}}</option>
        </select><br/>


        <!--<button type="button" v-if="!question" class="btn btn-primary" @click="questionGet">GO !</button>-->

        <div v-if="question && question.question">
            <div class="question">
                <span><u>Question</u> : </span><b>{{question.question}}</b>
                <!--
                <i v-if="utterance" @click="speakQuestion" class="bi bi-megaphone display-5 text-secondary"></i>&nbsp;
                <i v-if="recognition" @click="startVoice" class="bi bi-mic display-5 text-secondary"></i>&nbsp;
                <i v-if="question.answer" @click="answerSend" class="bi bi-send display-5 text-danger"></i>
                -->
            </div>
            <!--<textarea class="form-control" v-model="question.answer" rows="4" />-->
            <textarea v-model="answer" placeholder="votre réponse à la question..." rows="4" cols="100"></textarea>
            <button type="button" :disabled="answer==''" @click="answerSend" class="btn btn-primary">Envoyer</button>
            <!--<button type="button" class="btn btn-primary" @click="answerSend">Valider</button>-->

        </div>
        <div v-else class="">
          Plus de questions pour ce choix. Allez-voir dans la synthèse.
        </div>

        <div v-if='history'>
        <br/>
            <div>
              <div class="question">[{{history[0].createdAt ? history[0].createdAt.substr(0,16) : '---'}}] {{history[0].question_id ? history[0].question_id.question : '????'}}</div>
              <div :class="'answer grade'+history[0].grade"><b>{{history[0].grade}}</b> : {{history[0].answer}}</div>
              <div class="reason">{{history[0].reason}}</div>
            </div>
        </div>
    </div>

    <!-- ******************************************************************************** -->

    <div v-if="$route.params.type == 'synthese'">
        <h2>Synthèse</h2>

        <Radar v-if="rdata.labels" style="height: 50vh; width: 50vh" :chart-data="rdata" :chart-options="roptions"/>

        <div v-if="false">
        Bar : <Bar ref="chart1" id="chart1" :options="{responsive:true}" :data="{labels:['a','b','c'], datasets: [ { data: [40, 20, 12] } ]}"/>
        </div>

        <div class="history" v-if="user">
            <div v-for="(s,is) in user.syntheses" :key="is">
              <div class="synt">{{s.techno}} : note globale: {{s.grade}} ({{s.N}} questions) le {{s.dt.substr(0,10)}}</div>
              <div><u>Positif</u>: {{s.positif}}</div>
              <div><u>A améliorer</u>: {{s.a_ameliorer}}</div>
              <div><u>Conclusion</u>: {{s.conclusion}}</div>
            </div>
        </div>


    </div>

    <!-- ******************************************************************************** -->
    <div v-if="$route.params.type == 'historic'">
        <h2>Votre historique questions/réponses</h2>
        <div class="history" v-if="this.history">
          <div v-for="(x,idx) in this.history" :key="idx">
            <div>
              <div class="question">[{{x.createdAt ? x.createdAt.substr(0,16) : '---'}}] {{x.question_id ? x.question_id.question : '????'}}</div>
              <div :class="'answer grade'+x.grade">{{x.answer}}</div>
              <div class="reason">{{x.reason}}</div>
            </div>
          <!--Votre réponse: {{feedback.answer}}<br/>
          Note: {{feedback.grade}}<br/>
          Raison: {{feedback.reason}}<br/>-->
          </div>
        </div>
    </div>

    <br/><br/>
    <BottomBar/>

    </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import NavBar from '@/components/NavBar.vue'
import BottomBar from '@/components/BottomBar.vue'
import { Bar } from 'vue-chartjs'
import { Radar } from 'vue-chartjs/legacy'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, PointElement, LineElement, CategoryScale, LinearScale, RadialLinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, PointElement, LineElement, CategoryScale, LinearScale, RadialLinearScale)

export default {
  data(){
      return {
        user: null,
        data:null,
        view: null,
        techno: null,
        technos:[],
        question: null,
        answer: null,
        history: null,
        recognition: null,
        utterance: true,
        roles: ['Elève', 'Administrateur'],

        french: null,

        rdata : {
                  labels: null, //['a','b','c'],
                  datasets: [{
                    label: 'compétences',
                    data: [0.0,5.0,2.5],
                    fill: true,
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgb(255, 99, 132)',
                    pointBackgroundColor: 'rgb(255, 99, 132)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(255, 99, 132)'
                  }]
                },
        roptions : {
            //height: '500px',
            responsive: true,
            maintainAspectRatio: true,
            scales:{r:{pointLabels:{font:{size: 20, color: '#880000'}}}},
            scale: {min: 0.0, max: 5.0,
                },
            },
      }
  },
  name: "UserView",
  //props: ['user'],
  components: {
    NavBar, BottomBar, Bar, Radar, //RadarChart,
  },
  methods: {
    speakQuestion() {
        let utterance = new SpeechSynthesisUtterance(this.question.question)
        utterance.lang='fr';
        speechSynthesis.speak(utterance);
    },
    startVoice() {
      this.recognition.start();
    },
    doneVoice(event) {
      console.log('VOICE', event.results[0][0].transcript)
      this.answer = event.results[0][0].transcript;
      console.log('done', event.results[0][0].transcript)
    },
    userUpdate() { // MaJ User & chart
      MyFlashDS.identifyUser().then(res=>{ // MaJ user (avec nouvelle synthèse)
        this.user=res.data;

        this.rdata.labels = this.user.syntheses.map(x=>x.techno);
        this.rdata.datasets[0].data = this.user.syntheses.map(x=>x.grade);
        console.log('RDATA', this.rdata)
      }).catch(()=>{
        this.$router.push('/');
      })
    },
    questionGet() {
        MyFlashDS.questionGet(this.techno).then(res=>{
            this.question=res.data;
            this.question.answer="...";
            // Si pas de question => synthese
            if (res.data.next) { // plus de question à répondre
              MyFlashDS.answerSynthese(this.techno).then(()=>{ // fait la synthèse
                this.userUpdate()
              })
            }

            console.log('voices:', speechSynthesis.getVoices())
            let getVoicebyLang = lang => speechSynthesis.getVoices().find(voice => voice.name==lang);
            this.french = getVoicebyLang('French (France)') || getVoicebyLang('Google français')
            console.log('french=', this.french)
        })
/*
        try{
            this.utterance = new SpeechSynthesisUtterance(this.question.question);
            this.utterance.lang='fr';
            this.utterance.voice=this.french;
            this.utterance.voiceURI=this.french.voiceURI; // peut planter...
        } catch (err) {
            console.log(err)
        }
*/
        this.recognition = new window.webkitSpeechRecognition();
        if (this.recognition) {
            this.recognition.lang = "fr-FR";
            this.recognition.onresult=this.doneVoice
        }
    },
    answerSend() {
        //MyFlashDS.answerPost({question_id: this.question.id, value: this.answer}).then(res=>{
        this.question.answer=this.answer
        MyFlashDS.answerPost(this.question).then(res=>{
            this.answer=''
            this.history.unshift(res.data);
            this.questionGet()
        })
    },
    technoChange(sel) {
        console.log('technoChange', sel.target.value);
        this.questionGet()
    },
  },
  mounted() {
    console.log('MOUNT UserData');
    console.log(this);

    // Plus utile...
    MyFlashDS.technoFindAll().then(res=>{
        this.technos=res.data;
    })

    // Historic
    MyFlashDS.answerGet().then(res=>{
        this.history=res.data;
    })

    this.userUpdate();
/*
    MyFlashDS.identifyUser().then(res=>{
        console.log('<MyFlashDS.identifyUser>', res.data);
        this.user=res.data;
        //this.$nextTick(this.questionGet);
      }).catch(()=>{
                this.$router.push('/');
              });
*/

/*
    MyFlashDS.questionGet().then(res=>{
        //let getVoicebyLang = lang => speechSynthesis.getVoices().find(voice => voice.name==lang);
        //let french = getVoicebyLang('French (France)') || getVoicebyLang('Google français')

        this.question=res.data;
        try{
            this.utterance = new SpeechSynthesisUtterance(this.question.question);
            this.utterance.lang='fr';
            this.utterance.voice=french;
            this.utterance.voiceURI=french.voiceURI; // peut planter...
        } catch (err) {
            console.log(err)
        }
        this.recognition = new window.webkitSpeechRecognition();
        if (this.recognition) {
            this.recognition.lang = "fr-FR";
            this.recognition.onresult=this.doneVoice
        }
    })
*/

  }
}
</script>


<style scoped>

div.info {color: brown;}

div.question.inline {color: black; font-size: 130%;}
div.question {color: black; font-size: 130%;}
div.answer.grade0 {color: red}
div.answer.grade1 {color: red}
div.answer.grade2 {color: orange}
div.answer.grade3 {color: orange}
div.answer.grade4 {color: green}
div.answer.grade5 {color: green}
div.reason {color: gray}

div.synt {margin-top:10px; font-size: 110%; color: blue;}

div.dt {color: blue };

/* site original */

div.dataView {
  color:  #d35400 ;
}

.icon-label{
  float: right;
  padding-right: 1vh;
}

.card-header {
  border-top: 5px solid #00B5EC;
  background: rgba(255, 255, 255, 1);
}



/*-------------------------------------------------------*/


div.block {
  color : red;
}

img.img_access {
  width: 15px; height: 15px; margin-bottom: 0.8vh;
}


.icon-label input {
  width: 20px;
}

button {
    cursor: pointer;
    width: 30vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    font-family: ITCAvantGarde, serif;
}

img.logo {
    object-fit: cover;
    height: 40vh;
}

input {
    border: 1px solid var(--couleur-principale);
    width: 45vh;
}












  .buttonsX {
    color: black;
    margin-top: 2vh;
    background-color: rgb(0, 133, 197);
    border-radius: 7px;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    cursor: pointer;
    width: 30vh;
    }

/*
  container: {
    height: "90vh";
    display: "flex";
    alignItems: "center";
    textAlign: "center";
    marginBottom: '10vh';
  }
  button: {
    color: "white";
    marginTop: "2vh";
    backgroundColor: "#0085c5";
    borderRadius: "7px";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    fontSize: "1rem";
    cursor: "pointer";
    width: "30vh";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  kezako: {
    color: "#0085c5";
    backgroundColor: "#FFF";
    marginTop: "2vh";
    border: "2px solid #0085c5";
    borderRadius: "7px";
    fontSize: "1rem";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    cursor: "pointer";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  lien: {
    fontSize: "0.8rem";
    textDecoration: "none";
    color: "grey"
  };
  responsive: {
    height: "40vh"
  }
*/

</style>
