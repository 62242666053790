<template>
  <div id="app">

      <div id="alert-container" class="position-fixed bottom-0 end-0 p-3" style="z-index:1">
          <b-alert v-for="(alert,ia) in alerts" :key="ia" :variant="alert.variant" show=3>{{alert.msg}}</b-alert>
      </div>

    <div class="container no-margin">

        <router-view @onAlert="onAlert"/>
    </div>
  </div>
</template>

<style>

.no-margin {
    margin:0 !important;
}
/*
@font-face {
  font-family: 'ITCAvantGarde';
  src: local('ITCAvantGarde'), url(fonts/ITCAvantGardeStd-Bk_0.ttf) format('truetype');
}

@font-face {
  font-family: 'ITCAvantGardeBold';
  src: local('ITCAvantGarde'), url(fonts/ITCAvantGardeStd-Bold_0.ttf) format('truetype');
}

@font-face {
  font-family: 'ITCAvantGardeDemi';
  src: local('ITCAvantGarde'), url(fonts/ITCAvantGardeStd-Demi_0.ttf) format('truetype');
}

@font-face {
  font-family: 'ITCAvantGardeBook';
  src: local('ITCAvantGarde'), url(fonts/ITCAvantGardeStd-Bk_0.ttf) format('truetype');
}
*/
:root {
  --couleur-principale: rgb(0, 133, 197);
  --couleur-secondaire: rgb(122, 181, 29);
  /*
  --couleur-tertiaire: #96e8b5;
  --couleur-texte: #444444;
  */
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: var(--couleur-principale) !important;
    border-color: var(--couleur-principale);
}

.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {
    background-color: var(--couleur-secondaire) !important;
    border-color: var(--couleur-secondaire);
}

.btn-outline-primary:hover{
    background-color: var(--couleur-principale) !important;
    border-color: var(--couleur-principale);
}

#alert-container {
  margin-bottom: 100px;
}

container: {
    height: "90vh";
    display: "flex";
    alignItems: "center";
    textAlign: "center";
    marginBottom: '10vh';
  }
</style>

<script>
//import BottomBar from '@/components/BottomBar.vue'
//import MainMenu from '@/components/MainMenu.vue'


export default {
  name: "app",
  data() {
    return {
      alerts: [],
    }
  },
  components: {
      //BottomBar,
      //MainMenu,
  },
  methods: {
    onAlert(variant, message) {
              this.alerts.push({msg: message, variant:variant})
    }
  },
  mounted() {
    this.onAlert("info", this.$i18n.t('gomyflash'));
  }
};
</script>
