<template>
  <div class="container justify-content-center">

<div class="container mb-5" style="border: medium;">
   <div class="row pt-5 justify-content-center">
      <div class="card card-outline-secondary col-sm-11 col-md-11 col-lg-8" style="border: 2px solid rgb(238, 238, 238); box-shadow: rgb(204, 204, 204) 0px 2px 3px; padding: 7vh;">
         <div class="mb-5">
            <h5 class="mb-4">Mes flashs :</h5>


            <vue-good-table styleClass="vgt-table condensed striped" max-height="500px" :columns="t_columns" :rows="tags">
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field == 'isAdmin'">XXX</div>
                </template>
            </vue-good-table>

<!--
            <div class="OLDrow">
               <div v-for="(row, index) in tags" :key="index" class="mr-3">
                 1.<img src="/flashsLogos/Animal02B.jpg" style="height: 100px; width: 100px;" alt="flash" title="4444">Nom: <input type="text" value=""><img src="/remove.png" style="width: 1.5rem; height: 1.5rem; margin-bottom: 0.8vh;">
               </div>
            </div>
-->
         </div>

<!--
         <div class="pr-2"><button type="submit" class="btn btn-primary col-12 col-md-6 col-lg-4 float-right">Enregistrer les modifications</button><button type="submit" class="btn btn-primary col-12 col-md-6 col-lg-4 float-right" data-toggle="modal" data-target="#addFlash">Ajouter un flash</button></div>
         <div class="modal fade" id="addFlash" tabindex="-1" role="dialog" aria-labelledby="addFlashLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" id="addFlashLabel">Pour ajouter un flash :</h5>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                  </div>
                  <div class="modal-body">
                     <ul>
                        <li>Scannez un flash vierge</li>
                        <li>Cliquez sur "Associer ce flash à mon compte"</li>
                        <li>Et voilà ! Le flash a bien été relié à votre compte.</li>
                     </ul>
                  </div>
                  <div class="modal-footer"><button type="button" class="btn btn-primary" data-dismiss="modal">Fermer</button></div>
               </div>
            </div>
         </div>
-->
      </div>
   </div>
</div>

</div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";

export default {
  data(){
      return {
        user: null,
        tags: null,
        t_columns: [
            {label: this.$i18n.t('no'), field: 'no',},
            {label: this.$i18n.t('customer'), field: 'customer',},
            {label: this.$i18n.t('createdAt'), field: 'createdAt',},
            ],

      }
  },
  name: "UserFlash",
  //props: ['user'],
  components: {
  },
  methods: {
  },
  mounted() {
    MyFlashDS.identifyUser().then(res=>{
        console.log('<MyFlashDS.identifyUser>', res.data);
        this.user=res.data;
        //this.data={customer: null, owner:this.user.id}
        MyFlashDS.getTags({owner:this.user.id}).then(res=>{
          this.tags=res.data;
        })
    });
  }
}
</script>


<style scoped>
</style>
