<template>

    <nav class="navbar fixed-bottom navbar-light bg-light">
      Centre IA © 2024
      <a href="https://centreia.fr/" target='centreia'>Centre IA</a>
      <a href="https://centreia.fr/" target='centreia'>A propos</a>
      <!--
      <a href="https://centreia.fr/" target='centreia'>Conditions</a>
      <a href="https://centreia.fr/" target='centreia'>Cookies</a>
      -->

      <!--<a class="navbar-brand" href="#">Fixed bottom</a>-->
    </nav>

</template>

<script>

export default {
  name: "BottomBar",
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<style>

</style>
