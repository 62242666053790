<template>
  <div class="container justify-content-center">

    <CircleComp :text="title"/>

    <div>

        <div class="row justify-content-center md-form md-outline">
            <input @change="myChange()" v-model="user.email" value="xxx@free.fr" :placeholder="$t('email')" />
        </div>
        <div v-if="!forgotten" class="row justify-content-center md-form md-outline">
            <input @change="myChange()" type="password" v-model="user.password" value="****" :placeholder="$t('password')" />
        </div>
        <div class="row justify-content-center">
           <div class=''><button @click="doConnect()" type="button" :disabled="!user.email || (!user.password && !forgotten)" class="btn btn-primary buttons text-uppercase">{{$t('validate')}}</button></div>
        </div>
        <div v-if="!forgotten" class="row justify-content-center">
            <a href="#" @click="forgotten=true; title=$t('passwordforgot')" style="text-decoration: none; color: grey;">{{$t('passwordforgot')}}</a>
        </div>
        <div class="row justify-content-center">
           <div class=''><button @click="doReturn()" type="button" class="btn btn-outline-primary buttons text-uppercase">{{$t('return')}}</button></div>
        </div>
    </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import CircleComp from '@/components/CircleComp.vue'

export default {
  data(){
      return {
        user:{},
        forgotten: false,
        title: this.$i18n.t('connexion'),
      }
  },
  name: "UserLogin",
  components: {
    CircleComp,
  },
  methods: {
    doConnect(){
      if (this.forgotten) {
        console.log('error', "Email envoyé...");
        MyFlashDS.forgotUser(this.user).then(res=>{
          console.log('error', res.data);
          this.$emit('onAlert', 'info', res.data);
          this.forgotten = false;
          this.title = this.$i18n.t('connexion')
        })
        return;
      }


      MyFlashDS.loginUser(this.user).then(res=>{
        this.$emit('onAlert', 'success', ('welcome, ')+res.data.pseudo);
        //this.$emit('doReturn', "OK");
        this.$router.push(this.$route.query.back ? this.$route.query.back : '/user/synthese');
      }).catch(error=>{
        console.log('error', error.message);
        //console.log('error', error);
        this.$emit('onAlert', 'warning', error.response.data.error);
      });
    },
    doReturn(){
      this.$emit('doReturn', "OK");
    },
    myChange() {
    },
  },
  mounted() {
        this.$emit('onAlert', 'danger', 'login');
  }
}
</script>


<style scoped>
button {
    cursor: pointer;
    width: 30vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    font-family: ITCAvantGarde, serif;
}

img.logo {
    object-fit: cover;
    height: 40vh;
}

input {
    border: 1px solid var(--couleur-principale);
    width: 45vh;
}












  .buttonsX {
    color: black;
    margin-top: 2vh;
    background-color: rgb(0, 133, 197);
    border-radius: 7px;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    cursor: pointer;
    width: 30vh;
    }

/*
  container: {
    height: "90vh";
    display: "flex";
    alignItems: "center";
    textAlign: "center";
    marginBottom: '10vh';
  }
  button: {
    color: "white";
    marginTop: "2vh";
    backgroundColor: "#0085c5";
    borderRadius: "7px";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    fontSize: "1rem";
    cursor: "pointer";
    width: "30vh";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  kezako: {
    color: "#0085c5";
    backgroundColor: "#FFF";
    marginTop: "2vh";
    border: "2px solid #0085c5";
    borderRadius: "7px";
    fontSize: "1rem";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    cursor: "pointer";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  lien: {
    fontSize: "0.8rem";
    textDecoration: "none";
    color: "grey"
  };
  responsive: {
    height: "40vh"
  }
*/

</style>
