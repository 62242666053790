<template>
<div>

    <Slide right :closeOnNavigation="true" > <!--@closeMenu="handleCloseMenu"-->
    <nav v-if="user" class="nav">
          <div class="nav-item justify-content-start" >
            <!--<div class="head"><router-link to="/user">{{$t('education')}}</router-link></div>-->
            <div class="head"><router-link to="/user/synthese">{{$t('synthese')}}</router-link></div>
            <div class="head"><router-link to="/user/question">{{$t('questions')}}</router-link></div>
            <div class="head"><router-link to="/user/historic">{{$t('historic')}}</router-link></div>
            <div class="head" v-if="user.roles[0].role==1"><router-link to="/admin/000000000000000000000000">{{$t('administration')}}</router-link></div>
            <div v-for="(r,ir) in user ? user.roles : []" :key="ir" class="small">
                <router-link v-if="r.role==2" :to="'/admin/'+r.organisation_id">&nbsp;&nbsp;Directeur {{r.organisation && r.organisation.name}}</router-link>
            </div>
            <div class="head"><router-link to="/logout">{{$t('logout')}}</router-link></div>
          </div>
    </nav>
    </Slide>

</div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import { Slide } from 'vue-burger-menu'  // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
  name: "NavBar",
  props: ['user', 'submenu'],
  watch: {
    user: function () {
        //this.orgChange()
        //alert('USER !')
        console.log('**** NavBar user', this.user)
    },
  },

  data() {
    return {
    };
  },
  components: {Slide},
  methods: {
    doClick(id){
        this.$router.replace('/admin/'+id).then(x=>{console.log('callback router',x)})
        console.log('doClick', id, this.$router)
    },
    doLogout(){
    this.$emit('doLogout');
      MyFlashDS.logoutUser().then(()=>{
        this.$emit('onAlert', 'success', 'logout');
        //this.user=null;
        this.$router.push('/');
        console.log('logout !')
      });
    },
    handleCloseMenu(){
        console.log('close menu')
        },
  },
  mounted() {
  }
};
</script>

<style scoped>

.nav ul li {display: block}


nav {
    display: block;
    text-align: left;
}

div.head {color: white;}
div.small {color: white; font-size: 80%}
div.pseudo {color: #00afef; text-align: center;}

a {
    color: white;
    text-decoration: none;
}



</style>
