/*

prop : text
*/

<template>
  <div class="container text-center">
    <div class="row justify-content-center">
        <div class="text-center">
            <img class="" src="/logo_coursGPT.png" alt="">
        </div>
    </div>
    <div class="row justify-content-center">
        <h1 class="text-uppercase">{{text}}</h1>
    </div>
  </div>
</template>

<script>
/* Code generated with AutoHTML Plugin for Figma */

export default {
  name: "CircleComp",
  components: {},
  props: ['text'],
  data() {
    return {};
  },
};
</script>

<style scoped>

h1 {
  color: var(--couleur-principale);
  font-size: 1vmax;
}

img {
  max-width: 200px;
}
</style>
