/*
  <!-- Exemple : <ImageUpdater @imageChange="imageChange"/> -->
*/

<template>

  <div>
                    <div ref="dropArea" class="drop-area" :data-idx="0"
                        @dragenter="highlight" @dragover="highlight"
                        @dragleave="unhighlight" @drop="handleDrop">
                        <img id="dropImg" ref="dropImg" :src="image"/>
                    </div>
  </div>
</template>

<script>
//import MyFlashDS from "../services/MyFlashDS";

export default {
  data(){
      return {
        image: '',
      }
  },
  name: "ImageUpdater",
  //props: ['id'],
  components: {
  },
  methods: {
        preventDefaults(evt) {
            evt.preventDefault();
            evt.stopPropagation();
        },
        highlight(evt){
            let target=evt.target;
            //console.log('highlight', target);
            if (!target.classList.contains('drop-area')) target=target.parentNode;
            target.classList.add('highlight');
            this.preventDefaults(evt);
        },
        unhighlight(evt){
            let target=evt.target;
            if (!target.classList.contains('drop-area')) target=target.parentNode;
            target.classList.remove('highlight');
            this.preventDefaults(evt);
        },
    previewFile(file, idx) {
        console.log('previewFile', file, idx);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            this.image=reader.result;
            this.$emit('imageChange', this.image);
        }
        let reader2 = new FileReader();
        reader2.readAsBinaryString(file);
        reader2.onloadend = () => {
            //this.image=reader.result;
            this.$emit('imageChangeBin', reader2.result);
        }

    },
    handleFiles(files, idx){
        console.log('handleFiles', files, idx);
        files = [...files]
        console.log('  handleFiles', files, idx);
        files.forEach((f) => {
            this.previewFile(f, idx);
            //this.files_array[idx]=f;
        });
    },
    fileChange(evt){
        let target=evt.target;
        if (!target.classList.contains('drop-area')) target=target.parentNode;
        console.log('fileChange', target, target.dataset.idx, evt.target.files);
        this.handleFiles(evt.target.files, target.dataset.idx);
    },
    handleDrop(evt) {
        let target=evt.target;
        if (!target.classList.contains('drop-area')) target=target.parentNode;
        console.log('handleDrop', evt.target, this.products);
        let dt = evt.dataTransfer
        let files = dt.files
        this.handleFiles(files, target.dataset.idx)
        this.preventDefaults(evt);
    },
  },
}
</script>

<style scoped>
.drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  //width: 480px;
  font-family: sans-serif;
  //margin: 100px auto;
  padding: 20px;
}
.drop-area.highlight {
  border-color: red;
}

img {
    max-width: 100px;
    max-height: 100px;
}
</style>