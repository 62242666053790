/*
Affiche le bandeau du Customer ou du user......
prop data = {user: IDxxx?, owner: IDxxx, customer: IDxxx}

*/

<template>
   <div class="col-md-8 offset-md-2 col-12 profile-main mt-4">

   <!--
   <div>BANDEAU {{data }}</div>
   -->

    <b-modal ref="imgModal" id="imgModal" @ok="doChangeImage">
        <ImageUpdater @imageChange="imageChange"/>
    </b-modal>

      <div class="row">
         <div class="col-md-12 col-12 user-detail-main border mb-3 pb-2">
            <div class="row align-items-center">
               <div class="col-md-12 col-12 profile-back pb-5 d-flex align-items-center justify-content-center" data-type='bandeau' @click="doUpdImage" :style="'background-image: url(\''+img.bandeau+'\');'">
               <img src="/Photo_Masque.png" style="height: 22vh; width: 22vh; position: absolute; z-index: 2; border-radius: 100%; cursor: pointer;" data-type='image' @click="doUpdImage" class="paused" alt="Profil">
               <img :src="img.image" style="position: absolute; z-index: 1; background-color: rgb(166, 166, 166);" alt="Profil0" data-type='XXX' @click="doUpdImage" class="rounded-circle img-fluid">
            </div>
            </div>
            <div class="row">
               <div class="col-md-12 col-12 user-detail text-center">
                  <h4 v-if="user" class="m-0"><strong>{{user.pseudo}}</strong></h4>
                  <div class="form-group"></div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import ImageUpdater from '@/components/ImageUpdater.vue'

export default {
  data(){
      return {
        img: {bandeau: '/default_bandeau.jpg', image: '/Mascotte_01B.png'},
        user: null,
        customer: null, owner: null,
        field:'', // image ou bandeau
        temp:'', // stokage image temp
      }
  },
  props:['data'], // TODO => {owner: XXX, customer: XXX}
  name: "Bandeau",
  components: {
    ImageUpdater,
  },
  methods: {
    doUpdImage(evt){
      if (this.data.customer) return;
      console.log('click', evt.target.dataset.type);
      evt.stopPropagation();
      this.field = evt.target.dataset.type;
      this.$refs.imgModal.show();
    },
    doChangeImage(){
      //console.log('change ', this.field+'0')
      this.img[this.field]=this.temp;
      let data={id:this.data.owner}
      data[this.field]=this.temp;
      MyFlashDS.saveUser(data).then(()=>{this.doLoadImages()})
    },
    imageChange(data){this.temp=data},
    doSetData(data) {
        this.img.bandeau = (data.bandeau ? process.env.VUE_APP_ROOT_API+'/..'+data.bandeau : '/default_bandeau.jpg')
        this.img.image = (data.image ? process.env.VUE_APP_ROOT_API+'/..'+data.image : '/Mascotte_01B.png')
    },
    doLoadImages(){
        console.log('Bandeau,data:', this.data)
        if (this.data.customer)
            MyFlashDS.getOneCustomer(this.data.customer, 'name bandeau image').then(res=>{
              this.customer=res.data;
              //this.img = res.data;
              this.doSetData(res.data)
                //this.img.bandeau = (this.img.bandeau ? process.env.VUE_APP_ROOT_API+'/..'+this.img.bandeau : '/default_bandeau.jpg')
                //this.img.image = (this.img.image ? process.env.VUE_APP_ROOT_API+'/..'+this.img.image : '/Mascotte_01B.png')
            });
        if (this.data.owner)
            MyFlashDS.dataGetUser(this.data.owner, 'id bandeau image').then(res=>{
              this.owner=res.data;
              if (!this.data.customer) this.doSetData(res.data);
              //this.user = res.data;
              //this.img = res.data;
              //this.bandeau = (this.data && this.data.customer && this.data.customer.image) ? this.data.customer.image : ((this.user && this.user.image) ? this.user.image : '/default_bandeau.jpg');
                //this.img.bandeau = (this.img.bandeau ? process.env.VUE_APP_ROOT_API+'/..'+this.img.bandeau : '/default_bandeau.jpg')
                //this.img.image = (this.img.image ? process.env.VUE_APP_ROOT_API+'/..'+this.img.image : '/Mascotte_01B.png')
            });
    },
  },
  mounted() {
    this.doLoadImages()
  }
}
</script>

<style scoped>

.user-detail {
  padding-top: 13vh;
}

.profile-back {
  height: 35vh;
  background-size: 100% 100%;
  background-size: cover;
  background-position: center;
}
.profile-back img {
  height: 20vh;
  width: 20vh;
  margin-top: 20vh;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
@-moz-document url-prefix(){
  .profile-back img{
    margin-top: 42vh;
  }
  .textOnPhoto{
    margin-top: 42v!important;
  }
}
</style>